import { isValidURL } from '../utility.service'

const AdobeLaunchScript = (
  options
) => {
  try {
    if(
      !!options &&
      !!options.url
    ) {
      const url = options.url

      if(
        !isValidURL(url)
      ) {
        console.log(`Please verify the adobe launch url: ${url}`)
        return
      }

      (e => {
        e.analyticsObject = ''
      })(window, document)

      return {
        src: url,
        defer: !!options.defer,
        async: !!options.async,
        beforeLoad: !!options.beforeLoad,
        onDCL: !!options.onDCL
      }
    }

    console.log(`Please verify the adobe launch url: ${options.url}`)
  } catch (
    e
  ) {
    console.log(e)
    console.log(`There is an error. Adobe launch cannot be loaded.`)
  }
}

export default AdobeLaunchScript