import { getCountryCodeAndLanguage, deleteCookie } from "../../src/services/utility.service"
import apiService from "../../src/services/api.service"
import API_ENDPOINTS from "../../src/constants/api-endpoints"
/**
 * get the login url based on country language
 * @return url of login page
 */
const getLoginUrl = () => {
  const {
    countryCode,
    langCode
  } = getCountryCodeAndLanguage()

  return decodeURIComponent(encodeURIComponent(`${window.location.origin}/${countryCode}/${langCode}/web/user/login`))
}

/**
 * remove from storage as well as cookie for login based information
 */
const setLogoutState = () => {
  window.sessionStorage.removeItem('AccountNumber')
  window.sessionStorage.removeItem('GatewayCustomer')
  window.sessionStorage.removeItem('state')
  window.sessionStorage.removeItem('accesstoken')
  deleteCookie('SessionId')
  deleteCookie('SessionDomain')
  deleteCookie('SessionInfo')
  window.location.href = getLoginUrl()
}

/**
 * terminate session if user pressed logout
 */
const terminateSessionService = async () => {
  const TERMINATE_SESSION = API_ENDPOINTS['TERMINATE_SESSION']
  const cookieStore = window.sessionStorage.cookiestore
  const {
    countryCode,
    langCode
  } = getCountryCodeAndLanguage()

  const uri = TERMINATE_SESSION['URI'].replace(/{{TIMESTAMP}}/g, new Date().getTime())
  const url = `${window.location.origin}${uri}`
  const method = TERMINATE_SESSION['METHOD'].toLowerCase()

  try {
    const requestPayload = JSON.parse(
      JSON.stringify(TERMINATE_SESSION['PAYLOAD'])
        .replace(/{{COUNTRY_CODE}}/g, countryCode)
        .replace(/{{LANGUAGE}}/g, langCode)
        .replace(/{{SESSION_ID}}/g, JSON.parse(cookieStore)[`SessionId`])
    )

    await apiService[method](url, requestPayload, {
      'Content-type': `application/json`,
      'Accept': `application/json`
    })

    setLogoutState()
  }catch (e) {
    setLogoutState()
  }
}

/**
 * set click event on logout link
 */
const setClickEventOnLogoutLink = () => {
  const item = document.getElementById(`user-logout-link`)
  const item2 = document.getElementById(`wu-mobile-logout-button`)
  item && item.addEventListener(`click`, terminateSessionService)
  item2 && item2.addEventListener(`click`, terminateSessionService)
}


export {
  setClickEventOnLogoutLink
}