/**
 * fetch the page data and make it available for rehydration
 */
const getPageData = async () => {
  const pageDataJsonUrl = document.getElementById('page-data').getAttribute('href')
  let pageData = await fetch(pageDataJsonUrl)
  pageData = await pageData.json()

  return pageData
}

export default getPageData
