import React from 'react'
import { createRoot } from "react-dom/client"

// import '@westernunion/icons/wuds.icons.min.css'

import getPageData from './services/get-page-data.service'
import loadNativeAssets from './loadNativeAssets'

import App from '../src/App'

import reportWebVitals from './reportWebVitals'

(async () => {
  window.pageData = await getPageData()

  const rootElement = document.getElementById("__wu")
  const root = createRoot(rootElement)

  root.render(
    <React.StrictMode>
      <App page-data={window.pageData} />
    </React.StrictMode>
  )

  loadNativeAssets()
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
