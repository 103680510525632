import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import ErrorPopup from './signupoverlay-error-component/signupoverlay-error.component'
import { isBrowser, getSwitches, getPageUrl, windowClick } from "../../services/utility.service"
import "./signupoverlay.component.scss"

/**
 * React SignupOverlayComponent Component
 * @component
 * @example
 * const data = {
 *  country: pageData['country'],
 *  language: pageData['language'],
 * }
 * return (
 * <SignupOverlayComponent data={data}  />
 * )
 */
const SignupOverlayComponent = ({
  data
}) => {
  const iframeRef = React.createRef()
  const popUpRef = useRef(null)
  const [errorState, setErrorState] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const styleObj = {
    'height_30': "height-30px",
    'height_56': "h56",
    'paddingBottom_10': "padding-bottom-10",
    'continueBtnEle': ".continue-button-container",
    'newRegisterMainEle': ".new-register-main",
    'newRegisterFlowEle': ".new-register-flow-container"
  }

  useEffect(() => {
    let app = JSON.parse(sessionStorage.getItem('app') || "{}")
    const isLoggedIn = app?.sender?.isLoggedIn
    let inIframe = window.location !== window.parent.location
    let currentCountry = data.country
    const switches = getSwitches()

    window.loadSignUpOverlay = () => {
      if (
        !isLoggedIn &&
        !inIframe &&
        currentCountry &&
        switches &&
        switches?.signUpOverlayEnabledCountries
          .toLowerCase()
          .indexOf(currentCountry.toLowerCase()) > -1
      ) {
        setShowOverlay(true)
      }
    }
  }, [])

  // Removes popup from DOM
  const close = () => {
    sessionStorage.setItem("closeOverLay", "true")
    popUpRef && popUpRef.current.remove()
  }

  if (
    isBrowser()
  ) {
    window.onSignUpOverlayPageChange = (pageName) => {
      pageName = pageName?.toLowerCase()
      const afterLogInPages = ["smo", "history", "receiver"]
      const pageNameEvents = {
        'not-found': () => {
          sessionStorage.setItem("loggedInByOverlay", "true")
          hideShowFrame("d-none", "d-flex")
        },
        'verify-email-overlay': () => {
          updatePinVerifyView()
          logAmplitudeEvent("verify-email-overlay")
        },
        'redirect-to-login': () => {
          redirection("login")
        },
        'existing-email': () => {
          hideShowFrame("d-none", "d-flex")
          setErrorState(true)
        },
        'increase-height': () => {
          increaseIFrameHeight(15)
        },
        'register': () => {
          if (!sessionStorage.getItem("signupOverlayExit")) {
            logAmplitudeEvent("signup-overlay")
            hideShowFrame("d-flex", "d-none")
            removeSpinner()
            customizedHeight()
          }
        }
      }

      if (afterLogInPages.indexOf(pageName) !== -1) {
        pageNameEvents['not-found']()
      } else {
        pageNameEvents[pageName]()
      }
    }

    // Attach click event on browser window
    windowClick(iframeRef, close)
  }

  // Get the page url
  const pageUrl = getPageUrl("register")

  // redirect to page and close the popup
  const redirection = page => {
    if(isBrowser()) {
      window.parent.location.href = getPageUrl(page)
      close()
    }
  }

  // Add and remove class from the iframe
  const hideShowFrame = (add, remove) => {
    const popup = popUpRef.current
    if(popup) {
      popup.classList.add(add)
      popup.classList.remove(remove)
    }
  }

  // Adding Height Dynamically on the basis of new register container element in the DOM
  const customizedHeight = () => {
    let totalHeight = 0
    const height = 530
    const refDocument = getOverlayView()
    const { continueBtnEle, height_30, height_56, newRegisterMainEle, paddingBottom_10, newRegisterFlowEle } = styleObj
    const continueButton = refDocument.querySelector(continueBtnEle)
    try {
      totalHeight = refDocument.querySelector(newRegisterFlowEle).offsetHeight
      if (totalHeight > height) {
        addClassToField(continueBtnEle, height_30, refDocument)
        !!continueButton && continueButton.classList.remove(height_56)
        addClassToField(newRegisterMainEle, paddingBottom_10, refDocument)
        iframeRef.current.style.height = `${totalHeight + 27}px`
      } else {
        iframeRef.current.style.height = `${totalHeight + 65}px`
      }

    } catch (error) {
      return error
    }
  }

  // Adding class dynamically inside the iframe
  const addClassToField = (field, newClass, refDocument) => {
    try {
      let fieldElement = refDocument.querySelector(field)
      !!fieldElement && fieldElement.classList.add(newClass)
    } catch (error) {
      return
    }
  }

  // Remove Spinner inside the iframe
  const removeSpinner = () => {
    const iFrameDocument = getOverlayView()
    if(iFrameDocument) {
      const spinnerSpace = iFrameDocument.querySelector(".spinner-wrapper")
      if (!spinnerSpace) {
        spinnerSpace.remove()
      }
    }
  }

  // Get Iframe ContentDocument Reference
  const getOverlayView = () => {
    return iframeRef.current?.contentDocument
  }

  // Adding height dynamically in the iframe
  const increaseIFrameHeight = (height) => {
    const totalHeight = iframeRef.current.offsetHeight
    if (totalHeight) {
      iframeRef.current.style.height = `${totalHeight + height}px`
    }
  }

  // Adding class in the iframe dynamically
  const updatePinVerifyView = () => {
    const popup = popUpRef.current
    popup && popup.classList.add("email-pin-popup")
  }

  // Setting Key PopupName in the session storage and logging popup event
  const logAmplitudeEvent = (popupName) => {
    sessionStorage.setItem("popUpName", popupName)
    window.amplitude && window.logPopUpEvent()
  }

  return (
    showOverlay ?
      <div id="popupContainer" ref={ popUpRef } className="position-fixed wu-overlay wu-overlay--dark">
        {!errorState && <>
          <div className="text-action-light d-flex justify-content-end cursor-pointer position-relative close-overlay" onClick={ () => hideShowFrame("d-none", "d-flex") } amplitude-id="button-close-overlay">X</div>
          <iframe ref={ iframeRef } className="wu-overlay__container bk-white signup-overlay-iframe" src={ pageUrl }></iframe>
        </>}
        {errorState && <ErrorPopup language={ data.language } country={ data.country }  redirection={ redirection }  />}
      </div> :
      <></>
  )
}

SignupOverlayComponent.propTypes = {
  "data": PropTypes.object.isRequired,
}

export default SignupOverlayComponent