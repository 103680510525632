import {
  isValidURL,
  getCurrentCountry,
  isCookieBucketingEnabled,
  getCookie,
  getEnvironment,
  getCountryBlogType
} from '../utility.service'

const EvergageScript = options => {
  try {
    if (!!options && !!options.url) {
      const {
        head,
        defer,
        async,
        onDCL,
        cookieBucketEnabledCountries,
        beforeLoad
      } = options

      const env = (getEnvironment().includes('production') ? 'prod' : 'uat')
      let senderCountry = getCurrentCountry()
      senderCountry = window.pageData.env.includes('blog') ? getCountryBlogType()?.toLowerCase() : senderCountry
      const country = senderCountry === "gb" ? "uk" : senderCountry
      let url =
        options.url
          .replace(new RegExp(/\${country}/, "g"), country)
          .replace(new RegExp(/\${env}/, "g"), env)
      let cookieOptin = getCookie("cookieOptIn")

      if (
        isValidURL(url) &&
        (cookieOptin === "" || !isCookieBucketingEnabled(cookieBucketEnabledCountries) || (isCookieBucketingEnabled(cookieBucketEnabledCountries) && cookieOptin !== null && cookieOptin.indexOf("CKTXNL") !== -1))
      ) {
        return {
          src: url,
          inHead: !!head,
          beforeLoad: !!beforeLoad,
          defer: !!defer,
          async: !!async,
          onDCL: !!onDCL,
        }
      }

      return
    }

    console.log(`Please verify the evergage url: ${options.url}`)
  } catch (e) {
    console.log(`There is an error. Evergage cannot be loaded.`)
  }
}

export default EvergageScript
