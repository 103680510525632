import { isValidURL, isMobileWeb } from "../utility.service"

const PwtGoogleAdScript = (options) => {

  try {
    if (!!options && !!options.url) {
      const { head, defer, async, beforeLoad, pageDataGoogleAd } = options
      let profileID = isMobileWeb() ? pageDataGoogleAd?.profileMobileId : pageDataGoogleAd?.profileWebId
      let url = ""
      if (profileID) {
        url =
        options.url
          .replace(new RegExp(/\${profileVersionId}/, "g"), profileID)
      }

      if (isValidURL(url)) {
        return {
          src: url,
          inHead: !!head,
          beforeLoad: !!beforeLoad,
          defer: !!defer,
          async: !!async,
        }
      }
      return
    }
    console.log(`Please verify the  pubmatic script url: ${options.url}`)
  } catch (e) {
    console.log(e)
    console.log(`There is an error. pubmatic script cannot be loaded.`)
  }
}

export default PwtGoogleAdScript