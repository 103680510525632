import { addScriptProps } from '../../../services/utility.service'

// load outage banner script in DOM
const loadBannerScripts = (
  url
) => {
  return new Promise((resolve, reject) => {
    let script = addScriptProps(url)
    script.addEventListener(`load`, () => {
      resolve(true)
    })
    script.addEventListener(`error`, () => {
      reject(false)
    })
    document.body.appendChild(script)
  })
}

export default loadBannerScripts