import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { fetchHTML, getEnv, isBrowser, addBaseUrlToString, replaceBaseUrl } from "../../../services/utility.service"
import loadBannerScripts from "./outage.service"
import "./outage-banner.component.scss"

/**
 * React outage Banner component
 * @component
 * @example
 * const pageData = {
 *  seo: {
 *    title: "Page title"
 *  },
 *  headerContent: "<p>Sample header contentt</p>",
 *  content: "<p>Sample body content</p>",
 *  footerContent: "<p>Sample footer contentt</p>"
 * }
 * return (
 *  <OutageBannerComponent />
 * )
 */
const OutageBannerComponent = () => {
  const baseURL = getEnv()
  let [data, setData] = useState([])

  useEffect(() => {
    const fetchMyBanner = async () => {
      let response = await fetchHTML(replaceBaseUrl(process.env.REACT_APP_OUTAGE_BANNER_HTML))
      let responseConfig = await loadBannerScripts(replaceBaseUrl(process.env.REACT_APP_OUTAGE_BANNER_CONFIGS))
      response && response.data && setData(response.data)
      responseConfig && loadBannerScripts(replaceBaseUrl(process.env.REACT_APP_OUTAGE_BANNER_LOGIC))
    }
    fetchMyBanner()
  }, [])

  const hostname = isBrowser() && window.location.hostname
  if (hostname && hostname.includes('localhost')) {
    if (data.length > 0) {
      data = addBaseUrlToString(data, baseURL)
    }
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}

OutageBannerComponent.propTypes = {
  content: PropTypes.string
}

export default OutageBannerComponent
