import {
  isValidURL,
  getCurrentCountry,
  isBrowser,
  isHomePage,
} from '../utility.service'

const BranchioScript = options => {
  try {
    if (!!options && !!options.url) {
      const url = options.url
      const apiKey = options.apiKey
      const isApplicable =
        (options.countryList.indexOf(getCurrentCountry().toUpperCase()) > -1 && isHomePage())

      if (isValidURL(url) && !!isApplicable && !!apiKey) {
        return {
          src: url,
          defer: !!options.defer,
          beforeLoad: !!options.beforeLoad,
          async: !!options.async,
          onDCL: !!options.onDCL,
          data: {
            "api-key": apiKey,
          },
          onload: () => {
            if (isBrowser()) window.branch.init(apiKey)
          },
        }
      }

      return
    }

    console.log(`Please verify the evergage url: ${options.url}`)
  } catch (e) {
    console.log(`There is an error. Evergage cannot be loaded.`)
  }
}

export default BranchioScript