

import AnalyticsService from '../services/analytics.service/analytics.service'
import  { loadScripts, getParameterByName, setUtmData } from '../services/utility.service'
import addUserInteractionEventHandle from '../services/add-user-interaction-event-handle'
import {
  manageSignupLoadOnElementExists,
  manageFormJsOnElementsExists,
} from "./manage-js-on-elements-exist-condition"
import { getStaticAssetsType } from './utility.service'

/**
 * Loading JavaScript client-side
 */
const loadNativeScripts = () => {
  let scripts = []
  const scriptsPath = `${window.pageData.staticassetsDomain}/${getStaticAssetsType()}/${window.pageData.ASSETS_VERSION}/scripts`

  scripts = scripts.concat([{
    src: `${scriptsPath}/${window.pageData.minified_native_js}`,
    defer: true,
    nonce: true
  }])

  if (
    window.pageData.Wubot_Enabled_Page &&
    window.pageData.Wubot_Enabled_Page.wubotEnabledPage === 'Yes'
  ) {
    scripts.push({
      src: `${scriptsPath}/vendors/openChat.js`,
      defer: true,
      nonce: true
    })
  }
  addUserInteractionEventHandle()
  // event for loading signup js
  document.addEventListener("signupLoadJs", manageSignupLoadOnElementExists)
  // differen forms JS based on element exists conditions
  setUtmData()
  loadScripts(scripts)
  document.addEventListener("formLoadEvent", manageFormJsOnElementsExists)

  getParameterByName('analytics') !== 'false' && AnalyticsService(window.pageData.ANALYTIC_OPTIONS)
}

export default loadNativeScripts
