const WuDataEventCaptureScript = options => {
  try {
    if (
      options
    ) {
      if (options.url) {
        return {
          src: options.url,
          beforeLoad: !!options.beforeLoad,
          defer: !!options.defer,
          async: !!options.async,
          onDCL: !!options.onDCL,
        }
      }

      return
    }

    console.log(`Please verify the Wu Data Event Capture url: ${options.url}`)
  } catch (e) {
    console.log(`There is an error. Wu Data Event Capture cannot be loaded.`)
  }
}

export default WuDataEventCaptureScript
