import {
  isValidURL,
  isBrowser,
  getCurrentCountry,
} from '../utility.service'

const AudioEyeScript = options => {
  try {
    const url = options.url
    let currentCountry = ""
    if (isBrowser()) {
      currentCountry = getCurrentCountry()
      if (currentCountry.length !== 2 && window.getCookie) {
        currentCountry =
          window.getCookie("WUCountryCookie_") ? window.getCookie("WUCountryCookie_") : window.getCookie("AKCountry")
      }
    }

    if (!!options && !!options.url) {
      const isAEAvailableForCurrentCountry = options.countryList.indexOf(currentCountry.toUpperCase()) > -1
      if (
        !isValidURL(url) ||
        !isAEAvailableForCurrentCountry
      ) {
        console.log(`Please verify the audio Eye launch url: ${url} or it is disabled for ${currentCountry}`)
        return false
      }

      return {
        src: url,
        defer: !!options.defer,
        beforeLoad: !!options.beforeLoad,
        async: !!options.async,
        onDCL: !!options.onDCL,
      }
    }

    console.log(`Please verify the audio Eye launch url: ${options.url}`)
  } catch (e) {
    console.log(e)
    console.log(`There is an error. Adobe launch cannot be loaded.`)
  }
}

export default AudioEyeScript