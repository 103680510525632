/* eslint-disable no-invalid-this */
/* eslint-disable no-prototype-builtins */

import { findMparticleState, isValidURL, loadScript } from '../utility.service'

const AmplitudeScript = (
  options
) => {
  try {
    if (
      !!options &&
      !!options.url
    ) {
      const url = options.url
      const apiKey = options.apiKey
      const mParticleState = findMparticleState()
      if (
        !isValidURL(url) ||
        !apiKey ||
        mParticleState === 'r4mparticle'
      ) {
        console.log(`Please verify the amplitude url: ${options.url} or check api key: ${apiKey}`)
        return
      }

      (e => {
        e.addEventListener('load', () => {
          e.amplitudeInit = () => {
            if (e.amplitude.runQueuedFunctions) {
              e.amplitude.runQueuedFunctions()
              e.amplitude.getInstance().init(
                apiKey,
                null,
                {
                  includeUtm: true,
                  includeReferrer: true,
                  saveParamsReferrerOncePerSession:
                    false
                }
              )
            } else {
              console.log("[Amplitude] Error: could not load SDK")
            }
          }

          let n = e.amplitude || { _q: [], _iq: {} }
          const s = (e1, t) => {
            e1.prototype[t] = (...args) => {
              this._q.push([t].concat(Array.prototype.slice.call(args, 0)))
              return this
            }
          }
          const o = () => {
            this._q = []
            return this
          }
          const a = ["add", "append", "clearAll", "prepend", "set", "setOnce", "unset"]
          for (let u = 0; u < a.length; u++) {
            s(o, a[u])
          }
          n.Identify = o
          const c = () => {
            this._q = []
            return this
          }
          const l = ["setProductId", "setQuantity", "setPrice", "setRevenueType", "setEventProperties"]
          for (let p = 0; p < l.length; p++) {
            s(c, l[p])
          }
          n.Revenue = c
          const d = ["init", "logEvent", "logRevenue", "setUserId", "setUserProperties", "setOptOut", "setVersionName", "setDomain", "setDeviceId", "setGlobalUserProperties", "identify", "clearUserProperties", "setGroup", "logRevenueV2", "regenerateDeviceId", "logEventWithTimestamp", "logEventWithGroups", "setSessionId", "resetSessionId"]
          const v = (e2) => {
            const t = (t1) => {
              e2[t1] = (...args) => {
                e2._q.push([t1].concat(Array.prototype.slice.call(args, 0)))
              }
            }
            for (let n1 = 0; n1 < d.length; n1++) {
              t(d[n1])
            }
          }
          v(n)
          n.getInstance = (e3) => {
            e3 = (!e3 || e3.length === 0 ? "$default_instance" : e3).toLowerCase()
            if (!n._iq.hasOwnProperty(e3)) {
              n._iq[e3] = { _q: [] }
              v(n._iq[e3])
            }
            return n._iq[e3]
          }
          e.amplitude = n
        })
      })(window, document)

      // Initialize the library with your `apiKey`.
      // amplitude.getInstance().init(apiKey);

      return {
        src: url,
        defer: !!options.defer,
        beforeLoad: !!options.beforeLoad,
        async: !!options.async,
        onDCL: !!options.onDCL,
        'data': {
          'api-key': apiKey
        },
        'onload': () => {
          if (window.amplitude.runQueuedFunctions) {
            window.amplitude.runQueuedFunctions()
            window.amplitude.getInstance().init(
              apiKey,
              null,
              {
                includeUtm: true,
                includeReferrer: true,
                saveParamsReferrerOncePerSession:
                  false
              }
            )
            const { WUDistDataAccess } = options.dependencies
            if (WUDistDataAccess) {
              loadScript({
                src: WUDistDataAccess.url,
                defer: !!WUDistDataAccess.defer,
                async: !!WUDistDataAccess.async,
              })
            }
          } else {
            console.log("[Amplitude] Error: could not load SDK")
          }
        }
      }
    }

    console.log(`Please verify the amplitude url: ${options.url}`)
  } catch (e) {
    console.log(`There is an error. Amplitude cannot be loaded.`)
  }
}

export default AmplitudeScript