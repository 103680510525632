import { getStaticAssetsType, loadScript } from "./utility.service"

/**
 * signupLoad if signupModalMain elements exists
 */
const manageSignupLoadOnElementExists = () => {
  const signupModalMain = document.getElementById("signupModalMain")
  if(signupModalMain) {
    const signupJsEl = document.querySelector(
      `script[src="${window.pageData.signupLoadJs}"]`
    )

    if(!signupJsEl) {

      // igloo script
      loadScript({
        src: `/${getStaticAssetsType()}/${window.pageData.ASSETS_VERSION}/scripts/vendors/igloo.js`,
        type: "text/javascript",
      })

      // loader_only js
      setTimeout(() => {
        loadScript({
          src: `/${getStaticAssetsType()}/${window.pageData.ASSETS_VERSION}/scripts/vendors/loader_only.js`,
          type: "text/javascript",
        })
      },1000)

      loadScript({
        src: window.pageData.signupLoadJs,
        type: "text/javascript",
      })
    }
  }
}


/**
 * all form load if forms js exists
 */
const manageFormJsOnElementsExists = () => {
  const formJsEl = document.querySelector(
    `script[src="${window.pageData.allFormLoadJs}"]`
  )
  !formJsEl && loadScript({
    src: window.pageData.allFormLoadJs,
    type: "text/javascript",
    defer: true,
    nonce: true
  })
}

export {
  manageSignupLoadOnElementExists,
  manageFormJsOnElementsExists,
}