import React from "react"
import PropTypes from "prop-types"
import WarningIcon from "../../../shared/svg/warning-icon.component"

/**
 * React ErrorPopup Component
 * @component
 * @example
 * const language = "en"
 * const country = "us"
 * const redirection = () => {}
 * return (
 * <ErrorPopup language={language} country={country}  redirection={redirection}  />
 * )
 */

const ErrorPopup = (
  props
) => {
  const emailID = sessionStorage.getItem("alreadyRegisteredEmailId") || "Your emailId"
  const storageKey = `translate_web_wucom_${props.country}_${props.language}`
  const sessionStorageKey = sessionStorage.getItem(storageKey)
  const translation = sessionStorageKey ? JSON.parse(sessionStorageKey["signup-overlay"]) : null

  // onLoginBtnClick redirect to login page
  const onLoginBtnClick = () => {
    props.redirection("login")
  }

  // onResetPassword Click redirect to register page
  const onResetPasword = () => {
    sessionStorage.setItem("overlayResetpassword", true)
    props.redirection("register")
  }

  return (
    <div className="wu-overlay-message wu-overlay-message--alert errorpopUp-containt">
      <i data-grunticon-embed="" className="wu-icon alert wu-icon__spacing-scale-xxl bk-white wu-overlay-message__icon">
        <WarningIcon />
      </i>
      <div className="wu-overlay-message__container wu-signup-error-border-top">
        <div className="wu-overlay-message__content">
          <h3 className="wu-overlay-message__content__title">{translation.alreadyRegisterd}</h3>
          <div id="error-msg" className="d-flex flex-direction wu-overlay-message__content__description"><span className="overlay-error-msg">{emailID} {translation.alreadyRegisterdErrorMsg}</span>
            <span className="overlay-error-msg">{translation.alreadyRegisterdLoginMsg}</span></div>
          <hr className="wu-overlay-message__line" />
          <button id="error-login" onClick={onLoginBtnClick} className="font-body-reg wu-btn wu-btn--round wu-btn--action-light wu-overlay-message__button">
            Login
          </button>
          <p id="overlay-reset-password" onClick={onResetPasword} className="wu-overlay-message__content__description font-body-sm text-action cursor-pointer">{translation.resetPassword}</p>
        </div>
      </div>
    </div>
  )
}

ErrorPopup.propTypes = {
  /**
   * page-data attributes
   */
  "country": PropTypes.string,
  "language": PropTypes.string,
  "redirection": PropTypes.function
}

export default ErrorPopup
