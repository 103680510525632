/**
 *  Cookie setting link configuration for OT
 *  @function
 */
const setCookieOptanononSettingConfig = () => {
  const cookieOptanonSettingEl = document.getElementById('CookieOptanonSetting')
  if (
    cookieOptanonSettingEl
  ) {
    cookieOptanonSettingEl.classList.add('optanon-show-settings')
    cookieOptanonSettingEl.href = `javascript:void(0)` // eslint-disable-line no-script-url

    /**
     * Below logic is added to fix cookie setting link issue.
     * Issue Description: On click of cookie setting link which is in footer section, OT popup should be displayed. This is not happening when first user directly click on it instead of clicking elsewhere on page.
     */
    let isLinkClicked = false
    cookieOptanonSettingEl.addEventListener('click', (e) => {
      if (
        !isLinkClicked
      ) {
        e.target.parentElement.click()
        e.target.click()
        isLinkClicked = true
      }
    })
  }
}

export default setCookieOptanononSettingConfig