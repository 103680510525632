import hideAppBlock from "./hide-app-block"

const oneLinkSmartScript = options => {
  try {
    if (
      options
    ) {
      if (
        options.url
      ) {
        return {
          src: options.url,
          beforeLoad: !!options.beforeLoad,
          defer: !!options.defer,
          async: !!options.async,
          onDCL: !!options.onDCL,
          'onload': () => {
            const isCorridorTemplate = window.pageData.template.templateName === "Corridor Template"
            let defaultValueOneLink = window.location.search ? encodeURIComponent(window.location.search.substring(1)) : 'dynamic_corridor_page'
            const vulcanDefaultValueOneLink = window.location.search ? encodeURIComponent(window.location.search.substring(1)) : 'vulcan_page'
            defaultValueOneLink = isCorridorTemplate ? defaultValueOneLink : vulcanDefaultValueOneLink
            const mediaSource = {keys: [""], defaultValue: defaultValueOneLink}
            let {oneLinkTemplateIdProd, campaignId} = window.pageData.dataCountryThemeSetting.nodes[0].countryThemeSettings
            const oneLinkURL = isCorridorTemplate ?
              `${window.pageData.oneLinkURL}${oneLinkTemplateIdProd}/` :
              `${window.pageData.oneLinkURL}${oneLinkTemplateIdProd}/${campaignId ? campaignId : ''}`
            hideAppBlock()
            const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
              oneLinkURL,
              afParameters: {
                mediaSource,
              },
            })

            if (result) {
              const result_url = result.clickURL
              const qrCodeElements = document.querySelectorAll(".onelink_qrcode")
              const appCodeElements = document.querySelectorAll(".onelink_appstore, [href^='https://smart.link']")
              Array.from(qrCodeElements).forEach((qrCodeElement, index) => {
                const qrImage = qrCodeElement.querySelector('img')
                qrImage?.classList.add("d-none")
                qrImage?.classList.remove('d-block')
                qrCodeElement.id = `onelink-qrcode-${index}`
                window.AF_SMART_SCRIPT.displayQrCode(qrCodeElement.id)
                let canvas = qrCodeElement.querySelector('canvas')
                canvas?.classList.add('w-100')
                canvas?.setAttribute('style', "border: 5px solid white;")
              })

              Array.from(appCodeElements).forEach((el) => {
                el.href =  result_url
              })
            }
          }
        }
      }

      return
    }

    console.log(`Please verify the oneLinkSmartScript url: ${options.url}`)
  } catch (e) {
    console.log(`There is an error. oneLinkSmartScript cannot be loaded.`)
  }
}

export default oneLinkSmartScript