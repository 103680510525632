import axios from "axios"

/**
 * generic axios post call
 * @param {string} url url
 * @param {object} data data to be passed
 * @param {object} headers headers to be passed
 * @return AxiosPromise
 */
const post = (url, data, headers) => {
  return axios({
    method: "post",
    url,
    data,
    headers
  })
}

export default { post }