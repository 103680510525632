import { isValidURL } from "../utility.service"

const GoogleAdScript = (options) => {

  try {
    if (!!options && !!options.url) {
      const { head, defer, async, beforeLoad } = options
      let url = options.url

      if (isValidURL(url)) {
        return {
          src: url,
          inHead: !!head,
          beforeLoad: !!beforeLoad,
          defer: !!defer,
          async: !!async,
          onload: () => {
            window.loadGoogleAd()
          },
        }
      }
      return
    }
    console.log(`Please verify the  google ad script url: ${options.url}`)
  } catch (e) {
    console.log(e)
    console.log(`There is an error. google ad script cannot be loaded.`)
  }
}

export default GoogleAdScript