import { isValidURL, getCookie, getCurrentCountry, getCurrentLanguage } from '../utility.service'

/**
 * Creates and returns one trust script object
 * @param {object} options Script options
 * @returns one trust object to inject script
 */
const OneTrustScript = (
  options
) => {
  const country = getCurrentCountry()
  const language = getCurrentLanguage()

  try {
    if(
      options &&
      options.url &&
      options.dataAttr &&
      options.dataAttr['domain-script']
    ) {
      const otScriptDomain = options.dataAttr['domain-script']
      let countriesWithOneTrustBanner = !!otScriptDomain
      const {
        url
      } = options

      const documentLanguage = options.dataAttr.documentLanguage || 'true'
      const otTest = getCookie("otTest")

      if (otTest && otTest === "true") {
        countriesWithOneTrustBanner = true
      }

      if(
        !isValidURL(url) && !countriesWithOneTrustBanner
      ) {
        console.log(`Please verify the one truest url: ${options.url} or check the country ${country}`)
        return
      }

      return {
        src: url,
        defer: !!options.defer,
        async: !!options.async,
        onDCL: !!options.onDCL,
        inHead: !!options.inHead,
        beforeLoad: !!options.beforeLoad,
        'data': {
          'document-language': documentLanguage || 'true',
          'domain-script': otScriptDomain
        },
        'onload': () => {
          if (
            countriesWithOneTrustBanner
          ) {
            let url1 = window.location.href || ''
            url1 = url1.indexOf("cookie-information") !== -1 ? document.referrer : url1
            let specialLangs = {
              'es-US': 'es-CO'
            }

            if (
              url1
            ) {
              if (country && language) {
                let browserLang = `${language}-${country.toUpperCase()}`
                /* replacing with specialLangs */
                document.documentElement.lang = specialLangs[browserLang] ? specialLangs[browserLang] : browserLang
              }
            }
          }

          window.OptanonWrapper = () => {
            let eOT = new Event('OneTrustGroupsUpdated')
            document.dispatchEvent(eOT)
            let dateN = new Date()
            dateN.setDate(dateN.getDate() + 365)
            let expires = `expires=${dateN.toUTCString()}`
            document.cookie = `userCookieOptIn=${window.OptanonActiveGroups}';domain=.westernunion.com;path=/;expires=${expires}`
            window.Optanon.OnConsentChanged(() => {
              // fix for PBL-6056, setting OneTrust specific cookies without path, to fix issues on global pages which doesn't have context path for countries
              // setting cookie for one year just like one trust doing
              let date = new Date()
              date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000)) // one year expiry
              let expires1 = `; expires=${date.toUTCString()}`

              let OptanonAlertBoxClosed = window.getCookie('OptanonAlertBoxClosed')
              document.cookie = `OptanonAlertBoxClosed=${OptanonAlertBoxClosed}${expires1};path=/`

              let OptanonConsent = window.getCookie('OptanonConsent')
              document.cookie = `"OptanonConsent=${OptanonConsent}${expires1};path=/`

              if (country.toLowerCase() !== 'us') {
                window.location.reload()
              }
            })
          }
        }
      }
    }

    console.log(`Please verify the One Trust url: ${options.url} or check the one trust is enabled for country ${country}`)
  } catch (e) {
    console.log(e)
    console.log(`There is an error. One Trust cannot be loaded.`)
  }
}

export default OneTrustScript