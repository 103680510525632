import { isValidURL } from "../utility.service"

const ChatBotScript = (options) => {
  try {
    if (!!options && !!options.url) {
      const { head, defer, async, beforeLoad } = options
      let url = options.url
      let {
        version,
        botID,
        authKey
      } = options.dataAttr

      if (
        !isValidURL(url) &&
        !version &&
        !botID &&
        !authKey
      ) {
        console.log(`Please verify the ChatBot configurations`)
        return
      }

      console.log(`Loading Chatbot version : ${version}`)
      // <div id=""skilai-x2n331-widget-container"" bot-lang=""en"" bot-id=""s_104_v3"" bot-auth-key=""RYYIYBHJERSDJSDJK"" class=""skil-ai-v-css""></div>
      const chatbotWidget = document.createElement("div")
      chatbotWidget.setAttribute("id", "skilai-x2n331-widget-container")
      chatbotWidget.setAttribute("bot-lang", "en")
      chatbotWidget.setAttribute("bot-id", botID)
      chatbotWidget.setAttribute("bot-auth-key", authKey)
      chatbotWidget.setAttribute("class", "skil-ai-v-css")
      document.body.appendChild(chatbotWidget)

      return {
        src: url,
        inHead: !!head,
        beforeLoad: !!beforeLoad,
        defer: !!defer,
        async: !!async,
      }
    }
  } catch (e) {
    console.log(e)
    console.log(`There is an error. ChatBot cannot be loaded.`)
  }
}

export default ChatBotScript