import ReCAPTCHA from "react-google-recaptcha"
import React, { useRef } from 'react'
import PropTypes from "prop-types"
import { isBrowser } from "../../services/utility.service"

/**
 * React GoogleRecaptchaComponent Component
 * @component
 * @example
 * const data = {
 *  googleRecaptchaV2SiteKey: key
 * }
 * return (
 * <GoogleRecaptchaComponent data={data}  />
 * )
 */
const GoogleRecaptchaComponent = ({
  data
}) => {
  if(!isBrowser()) return <></>
  const ref = useRef()
  const googleKey = typeof data.googleRecaptchaV2SiteKey  === 'string' ? JSON.parse(data.googleRecaptchaV2SiteKey) : data.googleRecaptchaV2SiteKey
  const visibleCaptcha = (window.sessionStorage.getItem('visibleCaptcha') || new URLSearchParams(window.location.search)?.get('visibleCaptcha')) === 'true'
  let googleKeyToUse = visibleCaptcha ? googleKey[1] : googleKey[0]
  const onChange = (captchaData) => {
    if (captchaData) {
      window.successCaptcha = true
      document.getElementById('captchaHumanKey').value = captchaData
      document.dispatchEvent(new CustomEvent("captcha-success"))
    }
  }

  const onError = () => {
    window.successCaptcha = false
    document.dispatchEvent(new CustomEvent('captcha-error'))
  }

  // useEffect(() => {
  //   document.addEventListener('createCaptcha', async () => {
  //     const captcha = await ref?.current?.execute()
  //     const receiveCaptchaEvent = new CustomEvent("receiveCaptcha", {
  //       detail: captcha,
  //     })
  //     document.dispatchEvent(receiveCaptchaEvent)
  //   })
  // },[ref])

  return (
    <ReCAPTCHA
      ref={ref}
      size={visibleCaptcha ? 'normal' : 'invisible'}
      sitekey={googleKeyToUse}
      onChange={onChange}
      onError={onError}
      onExpired={onError}
    />
  )
}

GoogleRecaptchaComponent.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GoogleRecaptchaComponent