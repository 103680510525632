import React from "react"
import PropTypes from "prop-types"
import AppBannerComponent from './app-banner-component/app-banner.component'
import OutageBannerComponent from './outage-banner-component/outage-banner.component'

/**
 * React Banner component
 * @component
 * @example
 * const bannerData = {
 *  bannerData: {
 *    title: "Page title"
 *  },
 * }
 * return (
 *  <BannerComponent />
 * )
 */

const BannerComponent = ({
  bannerData
}) => {
  return (
    <>
      {!bannerData.isWuplus && <AppBannerComponent bannerData={ bannerData } />}
      <OutageBannerComponent />
    </>
  )
}

BannerComponent.propTypes = {
  /**
   * page-data attributes
   */
  "bannerData": PropTypes.object,
}

export default BannerComponent