const Md5Script = options => {
  try {
    if (
      options
    ) {
      if (
        options.url
      ) {
        return {
          src: options.url,
          beforeLoad: !!options.beforeLoad,
          defer: !!options.defer,
          async: !!options.async,
          onDCL: !!options.onDCL,
        }
      }

      return
    }

    console.log(`Please verify the md5 url: ${options.url}`)
  } catch (e) {
    console.log(`There is an error. md5 cannot be loaded.`)
  }
}

export default Md5Script