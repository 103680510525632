const API_ENDPOINTS = {
  TERMINATE_SESSION: {
    URI: `/wuconnect/rest/api/v1.0/TerminateSession?timestamp={{TIMESTAMP}}`,
    METHOD: `POST`,
    PAYLOAD: {
      security: {
        session: {
          id: `{{SESSION_ID}}`
        }
      },
      bashPath: `/{{COUNTRY_CODE}}/{{LANGUAGE}}`
    }
  }
}

export default API_ENDPOINTS