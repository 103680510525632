import React from "react"
import PropTypes from "prop-types"

/**
 * React WarningIcon Component
 * @component
 * @example
 * const width = "32px"
 * const height = "32px"
 * width and height props are optional
 * return (
 * <WarningIcon width={width} height={height} />
 * )
 */

const WarningIcon = ({
  width,
  height
}) => {

  return (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>0187_icon-fraud-alerts</title>
      <desc>Created with Sketch.</desc>
      <g id="0187_icon-fraud-alerts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M16,2.0250468e-13 C24.836556,2.0250468e-13 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 -2.48689958e-14,24.836556 -2.48689958e-14,16 C-2.48689958e-14,7.163444 7.163444,2.0250468e-13 16,2.0250468e-13 Z M16.0025,4.62974398 C15.333553,4.62974398 14.7152741,4.98613788 14.38,5.565 L9.76,13.565 L5.145,21.565 C4.81090907,22.1436624 4.81095981,22.8566158 5.14513309,23.4352306 C5.47930636,24.0138455 6.09681823,24.3700001 6.765,24.3700001 L25.235,24.3700001 C25.9031818,24.3700001 26.5206936,24.0138455 26.8548669,23.4352306 C27.1890402,22.8566158 27.1890909,22.1436624 26.855,21.565 L22.235,13.565 L17.625,5.565 C17.2897259,4.98613788 16.671447,4.62974398 16.0025,4.62974398 Z M16,18.9099996 C16.1973658,18.9101316 16.3927655,18.9492115 16.575,19.025 C16.7468663,19.1010683 16.9055202,19.2040246 17.045,19.33 C17.1823188,19.4576136 17.291334,19.6126196 17.365,19.785 C17.4387943,19.9589123 17.4762284,20.1460832 17.4750293,20.335 C17.4751377,20.5307754 17.4360343,20.7245926 17.36,20.905 C17.2849809,21.0751641 17.1761031,21.2282734 17.04,21.355 C16.9003215,21.4794075 16.7416527,21.5806854 16.57,21.655 C16.3877655,21.7307885 16.1923658,21.7698684 15.995,21.7700005 C15.8013315,21.7701514 15.6094662,21.7327971 15.43,21.66 C15.2553363,21.594391 15.0967718,21.4920914 14.965,21.36 C14.8362316,21.2281244 14.7298349,21.076129 14.65,20.9099996 C14.5669837,20.7350285 14.5242686,20.5436652 14.5249908,20.35 C14.5260536,20.1591942 14.5651494,19.9705145 14.64,19.795 C14.7158717,19.6251094 14.8226892,19.4708174 14.955,19.34 C15.086012,19.2056352 15.2423582,19.098572 15.415,19.025 C15.6006473,18.9489261 15.7993706,18.9098608 16,18.9099996 Z M17.225,10.61 L17.225,17.72 L14.725,17.72 L14.725,10.61 L17.225,10.61 Z" id="Combined-Shape" fill="#000000"></path>
      </g>
    </svg>
  )
}

WarningIcon.defaultPropTypes = {
  /**
   * default prop values
   */
  "width": "32px",
  "height": "32px",
}

WarningIcon.propTypes = {
  /**
   * page-data attributes
   */
  "width": PropTypes.string,
  "height": PropTypes.string,
}

export default WarningIcon
