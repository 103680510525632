import { isValidURL, getCurrentCountry, isMobileWeb, getCookie } from "../utility.service"

const OptimizelyScript = (options) => {
  const country = getCurrentCountry()

  try {
    if (!!options && !!options.url) {
      const { head, defer, async, beforeLoad } = options
      let url = options.url

      if (isValidURL(url)) {
        return {
          src: url,
          inHead: !!head,
          beforeLoad: !!beforeLoad,
          defer: !!defer,
          async: !!async,
          onload: () => {
            const senderCtrAttr = {
              senderCountry: country.toLowerCase(),
              deviceType: isMobileWeb() ? 'R3_MOBILE_WEB' : 'R3_WEB',
              user_pricing_session: getCookie('userIdentity') ? getCookie('userIdentity') : "newCustomer"
            }
            if (window["optimizely"]) {
              window["optimizely"].push({
                type: "user",
                attributes: senderCtrAttr
              })
            }
          },
        }
      }
      return
    }
    console.log(`Please verify the  optimizely url: ${options.url}`)
  } catch (e) {
    console.log(e)
    console.log(`There is an error. optimizely cannot be loaded.`)
  }
}

export default OptimizelyScript